<template>
  <div class="work-page nike-matildas">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/M_1.jpg'"/>
    
  		<div class="meta">
  			<p class="client">NIKE</p>
  			<a class="project">MATILDAS WORLD CUP CAMPAIGN </a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Everything we ever dreamed of.</h1>
  			</div>
  			<div class="col-2">
  				<p>They are taking Australian football to places where no man has gone before. As football fans, the Matildas are everything we’ve ever dreamed of.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <img src="/static/images/M_2.jpg"/>

    <div style="position: relative;">
      <img src="/static/images/M_4.svg" style="max-width: 44.5%; position: absolute;right: -9%;top: -155px;"/>
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 59%">
        <img src="/static/images/M_3.jpg"/>
      </div>
      <div class="col" style="width: 36%; display: flex; flex-direction: column; justify-content: space-between;">
        <img style="margin-bottom: -180%;" src="/static/images/M_5.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/M_6.jpg" style="margin-top: 8%;"/>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 52%; margin-top: -25%;">
        <img src="/static/images/M_7.jpg"/>
      </div>
      <div class="col" style="width: 38%; margin-top: 35%;">

        <!-- Mobile video mockup -->
        <div class="mobile-mockup-wrapper">
          <div class="mobile-mockup-container" style="width: 100%">
            <video 
              width="100%" 
              height="auto" 
              src="/static/videos/M_8_VIDEO.mp4" 
              frameborder="0" 
              playsinline
              loop
              autoplay
              muted></video>
          </div>
        </div>
        
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/M_9.jpg" style="width: 80%; margin-top: 0%;" class="mobile-full" />

    <div class="spacer-lg"></div>

    <img src="/static/images/M_10.jpg"/>

    <div class="spacer-lg"></div>


    <div style="position: relative;">
      <img src="/static/images/M_4.jpg" style="max-width: 40%; position: absolute;right: -7%;top: -155px;"/>
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 95%">
        <img src="/static/images/M_11.jpg" style="width: 110%; margin-right: -10%;"/>
      </div>
      <div class="col" style="width: 50%; display: flex; flex-direction: column; justify-content: space-between;">
        <img style="margin-bottom: -200%; z-index: 1; position: relative;" src="/static/images/M_12.jpg"/>
      </div>
    </div>

    <div class="footer" style="margin-top: 26.5%;">
      <div class="prev">
        <router-link to="/ca-watch-me-2">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/ca-watch-me">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>


  </div>
</template>


<script>
export default {
  name: 'nike-matildas',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.nike-matildas {



}

</style>
